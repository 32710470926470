import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Hidden from "@material-ui/core/Hidden";

// Images
import whatsappIcon from "../images/v2/campaigns/social.png";
import SyncIcon from "../images/v2/channel/sync.svg";
import customizeEffectiveIcon from "../images/v2/channel/effective.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import remoteReadyBG from "../images/v2/channel/channel-bg.png";
import responsiveBG from "../images/v2/home/responsive-bg-img.svg";
import integrationBG from "../images/v2/home/integration-bg.svg";
import effectiveBG from "../images/v2/channel/effective-bg.svg";
import crmInsightsBgImg from "../images/v2/home/crm-insights-bg.svg";
// import WebinarModal from "../components/v2/WebinarModal";

const useStyles = makeStyles((theme) => ({
  homeSliderText: {
    [theme.breakpoints.down("sm", "xs")]: {
      textAlign: "center !important",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
    color: "#000000",
    border: "1px solid rgb(0 0 0 / 62%)",
    fontWeight: 600,
    // width: 307,
    // fontSize: 24,
  },
  clientLogoImg: {
    // maxWidth: 140,
    width: "100%",
    height: "auto",
    padding: "0 1rem",
    // filter: "grayscale(100%)",
  },
  cycleProcessImg: {
    maxWidth: "530px",
    height: "auto",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  crmInsightsimg: {
    maxWidth: "480px",
    height: "auto",
  },
  crmheadimg: {
    width: "100%",
    height: "auto",
  },
  featureItemContainer: {
    listStyleType: "none",
    padding: "0px",
  },
  featureItem: {
    display: "block",
    cursor: "pointer",
    border: "1px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    lineHeight: "22px",
    padding: theme.spacing(2),
    minHeight: "370px",
    "&:hover": {
      background: "#f2f9f9",
      borderColor: "#f2f9f9",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "380px",
      padding: "1rem",
    },
  },
  featureItemText: {
    fontSize: "16px !important",
  },
  remoteReadyRoot: {
    background: `url(${remoteReadyBG})`,
    backgroundColor: "#069bb8",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    paddingBottom: "0",
  },
  EffectivelyReach: {
    background: `url(${effectiveBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingTop: theme.spacing(12),
    padding: theme.spacing(2),
  },
  responsiveRoot: {
    background: `url(${responsiveBG}) center top`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      backgroundImage:
        "linear-gradient(241deg, #00b8d8 100%, #00b8d8 53%, #0893af)",
    },
  },
  integrationRoot: {
    background: `url(${integrationBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  collapseDivider: {
    backgroundColor: "#eaf0f6",
    color: "#6b6e71",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    color: "#6b6e71",
    marginBottom: theme.spacing(1),
    "&:hover": {
      background: "#a1dae2",
    },
  },
  numberBorder: {
    borderRight: "1px solid #e5e7e9",
  },
  contactUsCard: {
    boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    padding: theme.spacing(3),
    borderRadius: 10,
    border: "solid 1px #e2f0ff",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  blogCard: {
    boxShadow: "0 15px 20px 0 rgba(118, 130, 183, 0.18)",
    borderRadius: 5,
    padding: theme.spacing(2),
    marginTop: -2,
  },
  IntegratedCRMBg: {
    background: "linear-gradient(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  SyncCrm: {
    background: "linear-gradient(to bottom, #ffffff 0%, #e7f2fe 100%)",
    paddingBottom: "50px",
  },
  ConnectCrm: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center top`,
  },
  crmInsightsBg: {
    background: `url(${crmInsightsBgImg}) left top no-repeat`,
  },
  formSubmit: {
    fontSize: "16px",
    fontWeight: "600",
  },
  listItemText: {
    "& *": {
      fontSize: 16,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 24,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  sliderTestimonialImg: {
    boxShadow: "7px 20px 20px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
  },
  cimg: {
    "& .gatsby-image-wrapper": {
      filter: "grayscale(100%)",
      "&:hover": {
        filter: "none",
      },
    },
  },
  link: {
    cursor: "pointer",
  },
  getStartedBtn: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  videoContainer: {
    position: "relative",
  },
  "@keyframes smallScale": {
    from: {
      transform: "scale(1)",
      opacity: 1,
    },
    to: {
      transform: "scale(1.5)",
      opacity: 0,
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes triangleStroke": {
    from: {
      strokeDashoffset: 90,
    },
    to: {
      strokeDashoffset: 0,
    },
  },
  padLeft: {
    paddingLeft: "0px",
  },
  channelpageimg: {
    objectFit: "contain important",
    paddingBottom: "5px important",
  },
  imagedown: {
    top: "50px",
    position: "relative",
  },
  videoBtnCircle: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    backgroundColor: "#4cbb83",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    padding: "0px",
    top: "62.5%",
    left: "35.5%",
    zIndex: "2",
    "&:before, &:after": {
      content: '""',
      background: "rgba(76, 187, 131, 0.5)",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      width: "100%",
      animationName: "$smallScale",
      animationDuration: "3s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    },
    "&:after": {
      animationDelay: "0.5s",
      zIndex: -1,
    },
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "30px",
    height: "30px",
    transform: "translate(-50%, -50%)",
    "& .triangle": {
      animation: `$fadeIn 1.5s ${theme.transitions.easing.easeIn}`,
    },
    "& .path": {
      strokeDasharray: 90,
      strokeDashoffset: 0,
      animation: "$triangleStroke 1.5s",
      animationTimingFunction: `${theme.transitions.easing.easeIn}`,
    },
  },
  hasScaleAnimation: {
    animationName: "$smallScale",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  bold: {
    fontWeight: "500",
    color: "#333333",
  },
  careerPara: {
    fontSize: "21px",
    color: "rgb(46, 63, 79)",
    fontWeight: "500",
    margin: "0",
  },
}));

const security =
  `Send alerts, updates, and more over WhatsApp and Facebook from a single channel.
  Expedite your sales growth by responding to your Leads  instantly.
  Track the conversation and provide your Leads with a personalised experience.`
    .split(`
`);

const Channel = (props) => {
  const width = useWidth();
  const classes = useStyles();

  const [activePipeline, setActivePipeline] = React.useState(0);
  const [secondAccordian, setSecondAccordian] = React.useState(0);
  const [activemessagemanagement, setActivemessagemanagement] = React.useState([
    true,
    true,
    true,
  ]);
  const [activesync, setActivesync] = React.useState([true, true]);
  const accordian2 = React.useMemo(() => {
    const {
      data: { featureCollaborate, featureAssign, featureClosing },
    } = props;
    const pipeline = [
      {
        title: "Collaborate with the team",
        description: (
          <>
            Communicate faster and better with your potential clients. Invite
            and involve your team members to enable synergetic sales processes.
          </>
        ),
        img: getImage(featureCollaborate),
        alt: "Collaborate with the team",
      },
      {
        title: "Assign conversations",
        description: (
          <>
            Effortlessly notify and assign the conversations to the right team
            member.
          </>
        ),
        img: getImage(featureAssign),
        alt: "Assign conversations",
      },
      {
        title: "Add closing notes",
        description: (
          <>
            Add closing notes to each of your conversations to let your
            teammates know the special instructions or any other details about
            the prospect.
          </>
        ),
        img: getImage(featureClosing),
        alt: "Add closing notes",
      },
    ];

    return pipeline;
  }, []);
  const messagemanagement = [
    {
      title: "Message templates",
      description:
        "Initiate proactive conversations by sending out pre-approved message templates. If you miss out on the 24-hour message window after initiating the conversations, message templates will help you to keep your conversations going.",
    },
    {
      title: "Native experience",
      description:
        "Pepper Cloud CRM provides you with WhatsApp and Facebook native experience by allowing you to send message replies, media messages, delivery indicators, and more .",
    },
    {
      title: "Rich media",
      description:
        "Send out different types of media messages to your customers to create superior engaging and interactive conversations. Conveniently send text messages, emojis, images, videos, and documents.",
    },
  ];
  const sync = [
    {
      title: "Automatic Lead capture",
      description: (
        <>
          Don’t worry about manually saving the contact details of your Leads.
          Facebook and{" "}
          <a href="https://blog.peppercloud.com/whatsapp-crm-integration/">
            {" "}
            Whatsapp Business API
          </a>{" "}
          integrated CRM automatically captures all the data of your potential
          customer and add them to your CRM system.
        </>
      ),
    },
    {
      title: "Merge channels",
      description:
        "Talk to your Leads from a single channel. If someone chats with you from different messaging platforms, your CRM can automatically identify and merge your conversations into a single channel.",
    },
  ];
  const pipeline = React.useMemo(() => {
    const {
      data: {
        featureChannel,
        featurePrivateMessage,
        featureRealtimeConversation,
      },
    } = props;
    const pipeline = [
      {
        title: "One channel for all conversations",
        description: (
          <>
            With Channel integration, connect WhatsApp business account and
            Facebook messenger and provide your team with a single platform to
            respond to all the conversations.
          </>
        ),
        img: getImage(featureChannel),
        alt: "best CRM for channel integration",
      },
      {
        title: "Private messages or messages broadcast",
        description: (
          <>
            Send one-to-one private messages proactively to your Leads or
            broadcast the messages to a larger audience through WhatsApp or SMS,
            based on your requirements.
          </>
        ),
        img: getImage(featurePrivateMessage),
        alt: "Private messages or messages broadcast",
      },
      {
        title: "Real-time conversations",
        description: (
          <>
            Never make your potential customers wait for your replies. Reply
            instantly right from your CRM to any channel.
          </>
        ),
        img: getImage(featureChannel),
        alt: "Real-time conversations",
      },
    ];

    return pipeline;
  }, []);

  return (
    <React.Fragment>
      <SEO
        canonical="/multichannel-integration-for-sales-marketing"
        description="Get unified CRM tool with multichannel integration for all your sales marketing and customer support needs. Comes with popular messaging app integrations: WhatsApp, Facebook, Instagram, Telegram and more."
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
        keywords="multi channel integration,omnichannel crm,omni channel integration,omnichannel messaging,multichannel,Facebook messenger,WhatsApp,WhatsApp business account"
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        title="Best CRM with Multichannel Integration | Pepper Cloud"
      />
      <Box
        alignItems="center"
        display="flex !important"
        flexDirection="column"
        mt={6}
        pb={{ ms: 3, xs: 3 }[width] || 10}
        pt={3}
        textAlign="center"
      >
        <Grid alignItems="center" container justify="left" spacing={8}>
          <Grid item md={6} sm={12}>
            <Box display="flex" justifyContent={"flex-end"}>
              <Box
                className={classes.homeSliderText}
                m={0}
                maxWidth={600}
                p={1}
                textAlign="left"
              >
                <Box mb={4} mt={4}>
                  <Box alignItems="center" display="flex" mb={4} mt={4}>
                    <Box
                      alt="Sales CRM dashboard"
                      component={"img"}
                      src={whatsappIcon}
                    />
                    &nbsp;
                    <h2 className={classes.careerPara}>
                      WhatsApp, Facebook, SMS, Instagram & more
                    </h2>
                  </Box>
                </Box>
                <HeaderTypography component="h1" fontWeight={600} mt={0}>
                  Connect all your messaging channels in one place
                </HeaderTypography>

                <ParagraphTypography mb={{ md: 5 }}>
                  Create an intuitive and up-to-date communication channel for
                  your sales conversations. Pepper Cloud CRM syncs all your
                  conversations with contacts in real-time and saves you from
                  managing multiple channels on different platforms.
                </ParagraphTypography>
                <Box mb={6} textAlign="left">
                  <Button
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box m={0} textAlign="right">
              <StaticImage
                alt="Multi channel integration"
                className={classes.crmheadimg}
                placeholder="blurred"
                src="../images/v2/channel/channel-head-img.png"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2} mt={1}>
        <Divider light />
      </Box>
      <Box className={classes.ConnectCrm}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          mb={{ md: 2 }}
          textAlign="center"
        >
          <HeaderTypography component="h2" fontWeight={600} marginBottom="0">
            Generate leads from any where, any channel
          </HeaderTypography>
          <Grid container>
            <Grid item md={2} />
            <Grid item md={8}>
              <ParagraphTypography mb={{ md: 6 }}>
                Any incoming messages from different channels can be organised
                as Leads. Unify your communication channels, chat away with your
                prospects, and convert every conversation into sales
                opportunities effortlessly.
              </ParagraphTypography>
            </Grid>
          </Grid>
          <StaticImage
            alt="Best CRM for WhatsApp and Facebook"
            className={classes.img}
            src="../images/v2/channel/channelpage-second.png"
          />
        </Box>
      </Box>
      <Box mb={2} mt={1}>
        <Divider light />
      </Box>
      <Grid
        alignItems="center"
        className={
          width === "sm" || width === "xs" ? "" : classes.EffectivelyReach
        }
        container
        justify="center"
      >
        <Grid item md={6} sm={12}>
          <Box display="flex" justifyContent={{ sm: "center", md: "flex-end" }}>
            <Box maxWidth={620} mt={4} p={1}>
              <HeaderTypography
                alignItems="center"
                className={classes.accordionTitle}
                component="h2"
                display="flex"
                fontSize="1.75rem"
                fontWeight={700}
                justifyContent={{ sm: "center" }}
                lineHeight={1.4}
                mb={2}
              >
                <Box
                  alt="Sales CRM feature"
                  component="img"
                  height={{ sm: 40, xs: 40 }[width] || 100}
                  mr={{ sm: 2, xs: 2 }[width] || 3}
                  src={customizeEffectiveIcon}
                  textAlign="left"
                />
                Effectively reach out to your customers
              </HeaderTypography>
              <ParagraphTypography component="h3" mt={0} textAlign="left">
                With our powerful integration, meet and greet your customers in
                their favourite channels.
              </ParagraphTypography>
              <Box my={3}>
                <Divider light />
              </Box>
              {pipeline.map((each, index) => (
                <div key={each.title}>
                  <Box my={1} p={1} pl={0}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      onClick={() =>
                        setActivePipeline(index === activePipeline ? -1 : index)
                      }
                    >
                      <Box
                        color="text.secondary"
                        component="h3"
                        fontSize="h6.fontSize"
                        fontWeight="600"
                        m={0}
                      >
                        {each.title}
                      </Box>
                      <IconButton
                        aria-label="Expand or Collapse"
                        className={classes.expendButton}
                        color="default"
                      >
                        {index !== activePipeline ? (
                          <AddIcon fontSize="small" />
                        ) : (
                          <RemoveIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse in={activePipeline === index}>
                      <ParagraphTypography fontSize={16} mr={6} mt={2}>
                        {each.description}
                      </ParagraphTypography>
                    </Collapse>
                  </Box>
                  <Divider light />
                </div>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} sm={12}>
          <Box textAlign="right">
            <GatsbyImage
              alt={
                activePipeline !== -1
                  ? pipeline[activePipeline].alt
                  : pipeline[0].alt
              }
              image={
                activePipeline !== -1
                  ? pipeline[activePipeline].img
                  : pipeline[0].img
              }
            />
          </Box>
        </Grid>
        <Grid item md={2} sm={12}></Grid>
      </Grid>
      <Grid
        alignItems="center"
        className={classes.remoteReadyRoot}
        container
        justify="center"
      >
        {" "}
        <Container>
          <Box display="flex" justifyContent="center">
            <Box maxWidth={835}>
              <HeaderTypography
                color="common.white"
                component="h2"
                fontWeight={600}
                letterSpacing={0.25}
                lineHeight={1.25}
                mb={3}
              >
                One unified platform for the entire team
              </HeaderTypography>
              <ParagraphTypography
                color="common.white"
                fontSize={18}
                mt={0}
                textAlign="center"
              >
                Cooperate with your teammates on conversations that matter and
                create amazing experiences for your customers.
              </ParagraphTypography>
            </Box>
          </Box>
        </Container>
        <Grid item md={6} sm={12}>
          <Box display="flex" justifyContent={{ sm: "center", md: "flex-end" }}>
            <Box maxWidth={620} p={1}>
              <Box my={3}>
                <Divider light />
              </Box>
              {accordian2.map((each, index) => (
                <div key={each.title}>
                  <Box my={1}>
                    <Box
                      alignItems="center"
                      color="common.white"
                      display="flex"
                      justifyContent="space-between"
                      onClick={() =>
                        setSecondAccordian(
                          index === secondAccordian ? -1 : index
                        )
                      }
                      p={1}
                      pl={0}
                    >
                      <Box
                        component="h3"
                        fontSize="h6.fontSize"
                        fontWeight="600"
                        m={0}
                      >
                        {each.title}
                      </Box>
                      <IconButton
                        aria-label="Expand or Collapse"
                        className={classes.expendButton}
                        color="inherit"
                      >
                        {index !== secondAccordian ? (
                          <AddIcon fontSize="small" />
                        ) : (
                          <RemoveIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse in={secondAccordian === index}>
                      <ParagraphTypography
                        color="common.white"
                        fontSize={16}
                        mt={2}
                        p={1}
                        pl={0}
                      >
                        {each.description}
                      </ParagraphTypography>
                    </Collapse>
                    {index + 1 !== secondAccordian.length && (
                      <Divider className={classes.collapseDivider} light />
                    )}
                  </Box>
                </div>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} sm={12}>
          <Box className={classes.imagedown} textAlign="right">
            <StaticImage
              alt="Sales CRM dashboard"
              className={classes.imgtop}
              src="../images/v2/channel/Group 10506.png"
            />
          </Box>
        </Grid>
        <Grid item md={2} sm={12}></Grid>
      </Grid>
      <Box component={Container} mt={6} pt={6} textAlign="center">
        <Box pb={{ sm: 3, xs: 3 }[width] || 8} pt={0}>
          <HeaderTypography
            alignItems="center"
            className={classes.accordionTitle}
            component="h2"
            display="flex"
            fontSize="1.75rem"
            fontWeight={700}
            justifyContent="center"
            lineHeight={1.4}
            mb={2}
          >
            Seamless message management
          </HeaderTypography>
          <Box display="flex" justifyContent="center"></Box>
        </Box>

        <Grid container spacing={4}>
          {messagemanagement.map((each, index) => (
            <Grid item key={each.title} md={4} sm={12}>
              <Divider light />
              <Box
                my={{ sm: 0, xs: 0, md: 1 }}
                pb={{ xs: 0, sm: 0, md: 2 }}
                pt={2}
                px={2}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  onClick={() => {
                    activemessagemanagement[index] =
                      !activemessagemanagement[index];
                    setActivemessagemanagement([...activemessagemanagement]);
                  }}
                >
                  <Box
                    color="text.secondary"
                    component="h3"
                    fontSize="h6.fontSize"
                    fontWeight="600"
                    m={0}
                  >
                    {each.title}
                  </Box>
                  <IconButton
                    aria-label="Expand or Collapse"
                    className={classes.expendButton}
                    color="default"
                  >
                    {!activemessagemanagement[index] ? (
                      <AddIcon fontSize="small" />
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={activemessagemanagement[index]}>
                  <ParagraphTypography
                    fontSize={16}
                    mb={{ sm: 0, xs: 0 }}
                    minHeight={{ md: 120 }}
                    mr={6}
                    mt={2}
                    textAlign="initial"
                  >
                    {each.description}
                  </ParagraphTypography>
                </Collapse>
              </Box>
              <Hidden smDown>
                <Divider light />
              </Hidden>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
        <br />
        <Grid container mb={6} mt={6} spacing={6}>
          <Grid item md={2}></Grid>
          <Grid item mb={6} md={8} mt={6} xs={12}>
            <div pb={6} pt={6}>
              <StaticImage
                alt="Automatic Lead capture CRM"
                className={classes.img}
                maxWidth={600}
                pb={6}
                pt={4}
                src="../images/v2/channel/Seamless image@2x.png"
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
      </Box>
      <Grid className={classes.SyncCrm}>
        <Box component={Container} textAlign="center">
          <Box pb={{ sm: 3, xs: 3 }[width] || 8} pt={0}>
            <HeaderTypography
              alignItems="center"
              className={classes.accordionTitle}
              component="h2"
              display="flex"
              fontSize="1.75rem"
              fontWeight={700}
              justifyContent="center"
              lineHeight={1.4}
              mb={2}
            >
              <Box
                alt="Customisable pipeline"
                component="img"
                height={{ sm: 40, xs: 40 }[width] || 89}
                mr={{ sm: 2, xs: 2 }[width] || 3}
                src={SyncIcon}
              />
              Sync every sales conversation
            </HeaderTypography>
            <Box display="flex" justifyContent="center"></Box>
          </Box>

          <Grid container spacing={4}>
            {sync.map((each, index) => (
              <Grid item key={each.title} md={6} sm={12}>
                <Divider light />
                <Box
                  my={{ sm: 0, xs: 0, md: 1 }}
                  pb={{ xs: 0, sm: 0, md: 2 }}
                  pt={2}
                  px={2}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    onClick={() => {
                      activesync[index] = !activesync[index];
                      setActivesync([...activesync]);
                    }}
                  >
                    <Box
                      color="text.secondary"
                      component="h3"
                      fontSize="h6.fontSize"
                      fontWeight="600"
                      m={0}
                    >
                      {each.title}
                    </Box>
                    <IconButton
                      aria-label="Expand or Collapse"
                      className={classes.expendButton}
                      color="default"
                    >
                      {!activesync[index] ? (
                        <AddIcon fontSize="small" />
                      ) : (
                        <RemoveIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={activesync[index]}>
                    <ParagraphTypography
                      fontSize={16}
                      mb={{ sm: 0, xs: 0 }}
                      minHeight={{ md: 120 }}
                      mr={6}
                      mt={2}
                      textAlign="initial"
                    >
                      {each.description}
                    </ParagraphTypography>
                  </Collapse>
                </Box>
                <Hidden smDown>
                  <Divider light />
                </Hidden>
              </Grid>
            ))}
          </Grid>
          <Grid container mb={6} mt={6} spacing={6}>
            <Grid item md={1}></Grid>
            <Grid item mb={6} md={10} mt={6} xs={12}>
              <div pb={6} pt={6}>
                <StaticImage
                  alt="Automatic Lead capture CRM"
                  className={classes.img}
                  maxWidth={600}
                  pb={6}
                  pt={4}
                  src="../images/v2/channel/Sync-image@2x.png"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box
        className={width === "sm" || width === "xs" ? "" : classes.smartCrmTool}
        mt={6}
      >
        <Box component={Container}>
          <Box display="flex" justifyContent="center">
            <Box pb={3}>
              <Grid alignItems="center" container justify="center" spacing={2}>
                <Grid item md={7} sm={12} xs={12}>
                  <HeaderTypography
                    alignItems="center"
                    component="h2"
                    display="flex"
                    fontSize="1.75rem"
                    justifyContent="left"
                    mb={3}
                    paddingLeft="16px"
                  >
                    Never let any sales <br /> conversations go unheard
                  </HeaderTypography>
                  <Box mb={6} pr={{ sm: 1, xs: 1 }[width] || 8}>
                    <List component="h4" fontSize="h6.fontSize">
                      {security.map((each) => (
                        <ListItem alignItems="center" key={each}>
                          <ListItemIcon>
                            <CheckIcon className={classes.listItemIcon} />
                          </ListItemIcon>
                          <ListItemText className={classes.listItemText}>
                            {each}
                          </ListItemText>
                        </ListItem>
                      ))}
                      <ListItem alignItems="center" key="sales-pipeline">
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          Keep your sales pipeline flowing by automatically
                          capturing the Leads into your CRM.
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                  <Box mb={6}>
                    <Button
                      color="secondary"
                      component={Link}
                      size="large"
                      to="/contact"
                      variant="contained"
                    >
                      Get Started
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                  <Box textAlign="center">
                    <StaticImage
                      alt="Best CRM for Facebook leads and WhatsApp Leads"
                      className={classes.cycleProcessImg}
                      placeholder="blurred"
                      src="../images/v2/channel/conversation.png"
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <br />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export const query = graphql`
  query {
    featureChannel: file(
      sourceInstanceName: { eq: "channel" }
      name: { eq: "Effectively image@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featurePrivateMessage: file(
      sourceInstanceName: { eq: "channel" }
      name: { eq: "broadcast" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureRealtimeConversation: file(
      sourceInstanceName: { eq: "channel" }
      name: { eq: "live-chat" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
  }
`;

Channel.propTypes = {
  data: PropTypes.object,
};

export default Channel;
